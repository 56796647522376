import React, {useContext, useEffect, useState} from 'react';
import {ClientWidthContext} from '../Context/ApplicationContextProvider';
import {axiosInstance} from '../../common';
import Icon from '../Icon/Icon';

function Banner(props) {
  const {link, picture, pictureMobile, ajaxPath, setShow} = props;

  const clientWidth = useContext(ClientWidthContext);
  const isMobile = clientWidth < 1024;

  async function onClose(event) {
    if (isMobile) {
      window.sessionStorage.setItem('mobileTopBanner', 'hide');
      setShow(false);
    }
  }

  return (
    <div id={'top-banner'}
      className={`flex items-center justify-center w-full h-8 overflow-hidden`}>
      <a href={link} className="contents">
        <img src={isMobile ? pictureMobile : picture} alt="banner"
             className="w-full h-full object-cover"/>
      </a>
      {
        isMobile && (
          <Icon name={'cancel-ico'}
                className={'absolute right-2 w-[28px] h-[28px]'}
                onClick={event => (onClose(event))}/>
        )
      }
    </div>
  );
}

export default Banner;