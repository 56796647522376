import axios from 'axios'

class NovaPoshta {
  constructor(lang, axiosConfig) {
    this.lang = lang || 'ua'
    this.axiosConfig = axiosConfig
  }

  get descKey() {
    return (this.lang.toLowerCase() === 'ua') ? 'Description' : 'DescriptionRu'
  }

  findCity (cityRef) {
    return new Promise(resolve => {
      axios.post('/local/api/np/findCity', { ref: cityRef }, this.axiosConfig)
        .then(response => {
          if (response && response.hasOwnProperty('data')) resolve(response.data)
          else resolve(null)
        })
        .catch(reason => {
          console.log(reason)
          resolve(null)
        })
    })
  }

  findAreasCenter (regionRef) {
    return new Promise(resolve => {
      axios.post('/local/api/np/findAreasCenter', { ref: regionRef }, this.axiosConfig)
        .then(response => {
          if (response && response.hasOwnProperty('data')) resolve(response.data)
          else resolve(null)
        })
        .catch(reason => {
          console.log(reason)
          resolve(null)
        })
    })
  }

  searchCities (search_phase) {
    return new Promise(resolve => {
      axios.post('/local/api/np/searchCities', { query: search_phase }, this.axiosConfig)
        .then(response => {
          if (response && response.hasOwnProperty('data')) resolve(response.data)
          else resolve(null)
        })
        .catch(reason => {
          console.log(reason)
          resolve(null)
        })
    })
  }
}

export default NovaPoshta