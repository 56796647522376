import React, {useContext} from 'react';
import {useEffect, useState} from 'react';
import NovaPoshta from '../../api/novaposhta/NovaPoshta';
import Icon from '../Icon/Icon';
import CityListDropdown from './CityListDropdown';
import {
  axiosConfig,
  axiosInstance,
  eventEmitter,
  lang,
  apiBaseUrl,
} from '../../common/baseData';

function CitySwitch(props) {

  const [state, setState] = useState({
    cities: window.usedCityData &&
    window.usedCityData.hasOwnProperty('cityList') &&
    Array.isArray(window.usedCityData.cityList)
      ? window.usedCityData.cityList
      : [],
    deliveryCity: props.deliveryCity && typeof props.deliveryCity === 'object' ?
      props.deliveryCity : null,
  });

  const [currentCityId, setCurrentCityId] = useState(null);

  const [cityTitle, setCityTitle] = useState('');
  const [opened, setOpened] = useState(false);

  const npApi = new NovaPoshta(lang, axiosConfig);
  const npDescKey = lang === 'ua' ? 'Description' : 'DescriptionRu';

  /**
   * Смена заголовка актуального города
   */
  useEffect(() => {
    let title = '', cityId = null, cityTitle = null;

    state.cities.forEach(city => {
      if (city.checked === true) {
        cityTitle = city.title || '';
        cityId = Number(city.id);
      }
    });

    setCityTitle(
      state.deliveryCity && state.deliveryCity.hasOwnProperty(npDescKey) ?
        state.deliveryCity[npDescKey] :
        cityTitle,
    );

    setCurrentCityId(cityId);

    // сохраняем данные о городах в Storage
    window.sessionStorage.setItem('deliveryCities',
      JSON.stringify(state.cities));
  }, [state]);

  /**
   * TODO
   */
  useEffect(() => {
    eventEmitter.on('setDeliveryCity',
      data => (onSetDeliveryCity(data.city_ref).
        then(response => console.log(response))));

    eventEmitter.on('cityChange', data => {
      let switchCityId = Number(data.city_id);
      let needReload = Boolean(data.need_reload) || false;
      if (needReload && switchCityId === currentCityId) return;
      onExternalSwitch(switchCityId, needReload);
    });
  }, []);

  /**
   * Sets the delivery city.
   *
   * @param {string} npCityRef - The reference of the city.
   *
   * @return {Promise} - A promise that resolves with the response of the API request.
   */
  function onSetDeliveryCity(npCityRef) {
    return new Promise(resolve => {
      npApi.findCity(npCityRef).then(deliveryCity => {
        setState({...state, deliveryCity: deliveryCity});
        return request({action: 'setDeliveryCity', ref: npCityRef});
      }).then(response => {
        resolve(response);
      });
    });
  }

  function onExternalSwitch(switchCityId, reload) {
    let city = state.cities.find(
      cityItem => (Number(cityItem.id) === switchCityId));
    if (typeof city !== 'object') return;
    onSwitch(city, reload);
  }

  async function onSwitch(city, reload = false) {
    let cityId = Number(city.id);

    let response = await request(
      {action: 'cityChange', id: cityId, path: window.location.pathname});

    if (response && response.status) {
      // Очистка города доставки выбранного на странице товара
      window.localStorage.removeItem('delivery_city');

      if (reload) {
        if (response.link && typeof response.link === 'string') {
          window.location.href = response.link;
        } else {
          window.location.href = city.link;
        }
      } else {
        // обновляем state
        let currentCityList = [...state.cities];

        currentCityList.forEach(city => {
          if (city.checked &&
            Number(city.id) !== cityId) {
            city.checked = false;
          } else if (Number(city.id) === cityId) {
            city.checked = true;
          }
        });

        setState({deliveryCity: response.delivery, cities: currentCityList});
      }
    }
  }

  /**
   * Sends a request to the server using axios.
   *
   * @param {Object} data - The data to be sent in the request.
   * @returns {Promise} A Promise that resolves to the server response data if successful, or null if an error occurs.
   */
  function request(data) {
    let url = `${apiBaseUrl}region/${data.action}`;

    console.info(axiosInstance, url, data);
    return new Promise(resolve => {
      axiosInstance.post(url, data).
        then(response => (resolve(response.data))).
        catch(reason => (resolve(null)));
    });
  }

  return (
    <>
      <div className="relative flex gap-1.5 items-center"
              onClick={event => {
                event.preventDefault();
                setOpened(true);
              }}>
        <span className="text-[14px] leading-[21px]">{cityTitle}</span>
        <span className="h-full flex items-center">
          <Icon name={'dropdown-arrow'} className={'w-[13px] h-[8px]'}/>
        </span>
        {
          opened ?
            <CityListDropdown items={state.cities}
                              onClose={event => {
                                event.stopPropagation();
                                setOpened(false)
                              }}
                              currentCityId={currentCityId}
                              onSwitch={onSwitch}/> :
            <></>
        }
      </div>
    </>
  );
}

export default CitySwitch;