import React from 'react';
import Lang from "../../Lang/Lang";

function SpecialDealList (props) {
  const { promotions } = props;

  const monthsData = JSON.parse(Lang.getMessage('months'));

  function formatDate(activeTo, monthsData) {
    const [dateStr, timeStr] = activeTo.split(' ');
    const [day, month, year] = dateStr.split('.');

    const formattedMonth = monthsData[month] || '';

    return `${Lang.getMessage('before')} ${day} ${formattedMonth} ${year ? year : ''}`
  }

  return (
    <ul className="grid grid-cols-4 grid-rows-2 gap-5 w-full h-fit" >
      {Array.isArray(promotions) &&
        promotions.map((promotion, index) => (
          <li key={index} className="h-fit">
            <a href={promotion.url} rel="noopener noreferrer">
              <img src={promotion.previewPictureSrc} alt={promotion.title} className="w-full h-auto rounded" />
            </a>
            <a href={promotion.url} rel="noopener noreferrer"
              className="flex text-[14px] leading-[20px] font-semibold mt-2 line-clamp-3 h-[41px] overflow-hidden hover:underline hover:underline-offset-2 hover:decoration-blue-primary hover:text-blue-primary">
              {promotion.title}
            </a>
            <p className="text-gray-80 text-[13px] leading-[17px] mt-2">
              {formatDate(promotion.activeTo, monthsData)}
            </p>
          </li>
        ))}
    </ul>
  );
}

export default SpecialDealList