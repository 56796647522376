'use strict'

import './MobileCity.css'
import React from 'react'

function MobileCity (props) {
  const title = props.hasOwnProperty('messages') && props.messages.hasOwnProperty('CITY_SWITCH_CITY') ? props.messages.CITY_SWITCH_CITY : 'City'
  const currentCity = props.hasOwnProperty('actualCity') && props.actualCity.hasOwnProperty('NAME') ? props.actualCity.NAME : 'Whole Ukraine'

  return (
    <div className={'mobile-city'}>
      <span className={'mobile-city-title'}>{title}:</span>
      <span className={'mobile-city-value'} onClick={props.onClick}>{currentCity}</span>
      <i className={'mobile-city-link'} onClick={props.onClick}/>
    </div>
  )
}

export default MobileCity