import React, {useContext, useEffect, useRef, useState} from 'react';
import {
  ClientWidthContext, ModalContext, ModalDispatchContext,
} from '../Context/ApplicationContextProvider';
import {eventEmitter, langHomeDir} from '../../common/baseData';
import Icon from '../Icon/Icon';
import MobileSearchActivatorForm
  from '../MobileSearchActivatorForm/MobileSearchActivatorForm';

function SecondLineMobileSticky(props) {
  const {
    children,
    page,
  } = props;

  const clientWidth = useContext(ClientWidthContext);
  const modals = useContext(ModalContext);

  return (
    <div className={'flex items-center justify-between w-full'}>
      <MobileSearchActivatorForm/>
    </div>
);
}

export default SecondLineMobileSticky;