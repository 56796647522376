import messages from './messages';
import {lang} from '../../common/baseData';

class Lang {

  static getMessage(code) {
    return messages[`${lang}`].hasOwnProperty(code) ? messages[`${lang}`][code] : '';
  }
}

export default Lang;