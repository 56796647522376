import './MobileNav.css';
import React, {useEffect, useState} from 'react';
import CatalogIcon from './images/catalog-btn.svg';

function MobileNav(props) {
  const {openCatalog, items, catalogTitle} = {...props};

  return (
    <div className={'mobile-nav'}>
      <button type={'button'}
              onClick={openCatalog}>
        <img src={CatalogIcon}
             alt={'CatalogIcon'}
             title={'CatalogIcon'}
             width={'16px'}
             height={'16px'}/>
        <span>{catalogTitle}</span>
      </button>
      {
        items.hasOwnProperty('top') && Array.isArray(items.top) ?
          <ul className={'mobile-nav-top'}>
            {
              items.top.map((item, i) => {
                return <li key={i} className={item.class}><a
                  href={item.link}>{item.title}</a></li>;
              })
            }
          </ul> :
          <></>
      }
      {
        items.hasOwnProperty('workTime') && items.workTime ?
          <div className={'mobile-work-time'}>
            <div className="mobile-work-time-phone">
              <a className={`callback-icon`}
                 href={`tel:${items.workTime.phone.base}`}>
                <i className="ic-callback"></i>
              </a>
              <a className={`mobile-work-time-number`}
                 href={`tel:${items.workTime.phone.base}`}>
                <span>{items.workTime.phone.formatted}</span>
              </a>
            </div>
            <div className="mobile-work-days">
              <span>{items.workTime.days}&nbsp;</span>
              <span>{items.workTime.startTime}&nbsp;-&nbsp;{items.workTime.endTime}</span>
            </div>
          </div> :
          <></>
      }
      {
        items.hasOwnProperty('bottom') && Array.isArray(items.bottom) ?
          <ul className={'mobile-nav-bottom'}>
            {
              items.bottom.map((item, i) => {
                return <li key={i} className={item.class}><a
                  href={item.link}>{item.title}</a></li>;
              })
            }
          </ul> :
          <></>
      }
    </div>
  );
}

export default MobileNav;