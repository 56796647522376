import React from 'react';
import {hoverUnderlineClass} from '../../common/baseData';

function LangSwitch(props) {
  const [state, setState] = React.useState(window.langsData || {});
  const selectedClass = 'font-medium text-grey-90 cursor-default';
  const defaultClass = 'text-grey-80';

  return (
    <ul className="flex gap-3">
      {
        state.hasOwnProperty('langList') && Array.isArray(state.langList) ?
          state.langList.map((lang, index) => (
            <li key={index}>
              <a href={lang.link}
                 className={`bg-transparent border-0 p-0 text-[14px] leading-[21px] ${lang.selected ? selectedClass : defaultClass} ${hoverUnderlineClass}`}>
                {lang.title}
              </a>
            </li>
          )) :
          <></>
      }
    </ul>
  );
}

export default LangSwitch;