import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';
import {apiBaseUrl, axiosInstance, eventEmitter} from '../../common/baseData';

const ModalContext = createContext();
const ModalDispatchContext = createContext();
const ClientWidthContext = createContext();
const UserContext = createContext();
const UserDispatchContext = createContext();
const BasketContext = createContext();
const BasketDispatchContext = createContext();
const SiteContext = createContext();
const SearchContext = createContext(); // Новый контекст для данных поиска
const SearchDispatchContext = createContext(); // Новый контекст для управления состоянием поиска

const MODAL_GROUPS = {
  group1: new Set(['basket', 'search']),
  group2: new Set(['basket', 'navigation']),
  group3: new Set(['basket', 'mobileSearch']),
  // Добавьте сюда другие группы по мере необходимости
};

const MODAL_CLOSE_HANDLERS = {
  basket: () => {
    console.info('Additional logic for closing basket');
    eventEmitter.emit('closeBasket');
  },
  search: () => {
    console.info('Additional logic for closing search');
    eventEmitter.emit('closeSearch');

  },
  mobileSearch: () => {
    console.info('Additional logic for closing mobile search');
    eventEmitter.emit('closeMobileSearch');

  },
  navigation: () => {
    console.info('Additional logic for closing navigation');
    eventEmitter.emit('closeNavigation');
  },
  // Добавьте сюда другие обработчики по мере необходимости
};

function getModalGroup(modalType) {
  for (const [group, types] of Object.entries(MODAL_GROUPS)) {
    if (types.has(modalType)) {
      return group;
    }
  }
  return null;
}

function useWindowResize(setClientWidth) {
  useEffect(() => {
    const handleResize = () =>
      setClientWidth(window.document.documentElement.clientWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [setClientWidth]);
}

function searchReducer(searchData, action) {
  console.info(searchData, action);
  switch (action.type) {
    case 'update': {
      return {...searchData, ...action.payload};
    }
    case 'clear': {
      return {
        ...searchData,
        'query': '',
        'productList': [],
        'categoryList': [],
        'potentialQueryList': [],
        'total': 0,
      };
    }
    case 'setQuery':
      return action.payload.length <= 1 ?
        {...searchData, 'query': action.payload} :
        {
          ...searchData,
          'query': action.payload,
          'productList': [],
          'categoryList': [],
          'potentialQueryList': [],
          'total': 0,
        };
    default: {
      throw new Error('Unknown action: ' + action.type);
    }
  }
}

function ApplicationContextProvider({children}) {
  const [modals, dispatchModal] = useReducer(modalReducer, initialModals);
  const [user, dispatchUser] = useReducer(userReducer, initialUserData);
  const [basket, dispatchBasket] = useReducer(basketReducer, initialBasketData);
  const [clientWidth, setClientWidth] = useState(
    window.document.documentElement.clientWidth);
  const [searchData, dispatchSearch] = useReducer(searchReducer,
    initialSearchData);  // Редюсер состояния для данных поиска
  const siteData = window.siteSettings || {desktopLogo: '', mobileLogo: ''};

  useWindowResize(setClientWidth);

  /**
   * загрузка истории поиска и продвигаемых товаров
   */
  useEffect(() => {
    initialLoad();
  }, []);

  useEffect(() => {
    const handleCompareUpdated = (compareItemIds) => {
      dispatchUser({type: 'update', payload: {compareItemIds: compareItemIds}});
    };

    const handleWishlistUpdated = (data) => {
      let items =
        data.hasOwnProperty('items') && Array.isArray(data.items)
          ? data.items
          : [];

      dispatchUser({type: 'update', payload: {wishlistItemIds: items}});
    };

    eventEmitter.on('refreshCompareList', handleCompareUpdated);
    eventEmitter.on('refreshWishList', handleWishlistUpdated);

    return () => {
      eventEmitter.off('refreshCompareList', handleCompareUpdated);
      eventEmitter.off('refreshWishList', handleWishlistUpdated);
    };
  }, []);

  /**
   * Манипуляция css class корп клиента для отображения цены в legacy компонентах
   */
  useEffect(() => {
    if (user.isCorpClient) {
      window.document.body.classList.add('corporate-client');
    } else {
      window.document.body.classList.remove('corporate-client');
    }
  }, [user]);

  async function initialLoad() {
    let historyData = await loadHistory();
    let productsData = await loadPromotedProducts();

    let newHistoryList = historyData.hasOwnProperty('HISTORY')
      ? historyData.HISTORY
      : [];
    let newPromotedProducts = Array.isArray(productsData) ? productsData : null;

    dispatchSearch({
      type: 'update',
      payload: {
        'historyList': newHistoryList,
        'promotedProducts': newPromotedProducts,
      },
    });
  }

  async function loadHistory() {
    return new Promise(resolve => {
      axiosInstance.post(`${apiBaseUrl}search/history`, {'query': ''}).
        then(response => resolve(response.data)).
        catch(reason => console.log(reason));
    });
  }

  async function loadPromotedProducts() {
    return new Promise(resolve => {
      axiosInstance.post(`${apiBaseUrl}products/getPromoted`, {'limit': 4}).
        then(response => resolve(response.data)).
        catch(reason => console.log(reason));
    });
  }

  return (
    <ModalContext.Provider value={modals}>
      <ModalDispatchContext.Provider value={dispatchModal}>
        <ClientWidthContext.Provider value={clientWidth}>
          <UserContext.Provider value={user}>
            <UserDispatchContext.Provider value={dispatchUser}>
              <BasketContext.Provider value={basket}>
                <BasketDispatchContext.Provider value={dispatchBasket}>
                  <SiteContext.Provider value={siteData}>
                    <SearchContext.Provider value={searchData}>
                      <SearchDispatchContext.Provider value={dispatchSearch}>
                        {children}
                      </SearchDispatchContext.Provider>
                    </SearchContext.Provider>
                  </SiteContext.Provider>
                </BasketDispatchContext.Provider>
              </BasketContext.Provider>
            </UserDispatchContext.Provider>
          </UserContext.Provider>
        </ClientWidthContext.Provider>
      </ModalDispatchContext.Provider>
    </ModalContext.Provider>
  );
}

function handleBodyStyles(show) {
  if (document.body.clientWidth < 1024) {
    if (show) {
      // Пример действий при показе модальных окон
      document.body.classList.add('stop-scrolling');
    } else {
      // Пример действий при скрытии модальных окон
      document.body.classList.remove('stop-scrolling');
    }
  }
}

function modalReducer(modals, action) {
  /*console.info(modals, action);
  console.trace();*/
  switch (action.type) {
    case 'show': {
      // Добавляем работу со стилями body
      handleBodyStyles(true);

      const modalGroup = getModalGroup(action.modalType);
      if (modalGroup) {
        // Закрываем все модальные окна, которые не относятся к группе, чтобы открыть новое
        const newModals = modals.filter(
          (modalType) => getModalGroup(modalType) === modalGroup,
        );
        return [...newModals, action.modalType];
      }
      // Закрываем все модальные окна, если новый модальный тип не принадлежит ни к одной группе
      return [action.modalType];
    }
    case 'hide': {
      const newModals = modals.filter(
        (modalType) => modalType !== action.modalType);
      const modalGroup = getModalGroup(action.modalType);

      // Дополнительная логика для закрываемого типа модального окна
      if (MODAL_CLOSE_HANDLERS[action.modalType]) {
        MODAL_CLOSE_HANDLERS[action.modalType]();
      }

      if (!modalGroup) {
        // Например, выполняем какую-то доп. логику
        console.info(
          `Closing modal type that is not part of any group: ${action.modalType}`,
        );

        // Если мы закрываем все модальные окна
        if (action.modalType === 'all') {
          // Возвращаем пустой массив, так как все модальные окна закрыты
          handleBodyStyles(false);
          return [];
        }
      }

      if (newModals.length === 0) {
        // Если нет остатков модальных окон, возвращаем body стили в изначальное состояние
        handleBodyStyles(false);
      }

      return newModals;
    }

    // Обработка неизвестных типов действий
    default: {
      throw new Error('Unknown action: ' + action.type);
    }
  }
}

function userReducer(user, action) {
  console.info('userReduser', user, action);
  switch (action.type) {
    case 'update': {
      return {...user, ...action.payload};
    }
    default: {
      throw new Error('Unknown action: ' + action.type);
    }
  }
}

function basketReducer(basket, action) {
  switch (action.type) {
    case 'update': {
      return action.payload;
    }
    default: {
      throw new Error('Unknown action: ' + action.type);
    }
  }
}

const initialModals = [];
const initialUserData = window.userData || {
  isAuthorized: false,
  isCorpClient: false,
  name: '',
  esputnikId: null,
  cashback: 0,
  wishlistItemIds: [],
  compareItemIds: [],
  fUserId: null,
  city: null,
  company: null,
  menuLinks: [],
};

const initialSearchData = {
  query: '',
  start: true,
  direct: undefined,
  productList: [],
  categoryList: [],
  historyList: [],
  promotedProducts: null,
  potentialQueryList: [],
  total: 0,
  totalLink: '',
}; // Начальные данные для поиска

let basketItemsFromStorage = null;
try {
  basketItemsFromStorage = JSON.parse(window.localStorage.basketItemsData);
} catch (error) {
  console.error(error);
}
const initialBasketData = basketItemsFromStorage || null;

export {
  ApplicationContextProvider,
  ModalContext,
  ModalDispatchContext,
  UserContext,
  UserDispatchContext,
  BasketContext,
  BasketDispatchContext,
  ClientWidthContext,
  SiteContext,
  SearchContext, // Экспорт нового контекста
  SearchDispatchContext, // Экспорт нового контекста для управления состоянием поиска
};