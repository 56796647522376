import React, {useContext, useEffect, useRef, useState} from 'react';
import {
  ClientWidthContext, ModalContext, ModalDispatchContext,
} from '../Context/ApplicationContextProvider';
import {eventEmitter} from '../../common/baseData';
import MobileSearchActivatorForm
  from '../MobileSearchActivatorForm/MobileSearchActivatorForm';

function SecondLineMobileSticky(props) {
  const {
    children,
    page,
    showTopBanner
  } = props;

  const modals = useContext(ModalContext);

  return (
    <div className={'flex flex-col items-center justify-between w-full'}>
      <MobileSearchActivatorForm/>
    </div>
  );
}

export default SecondLineMobileSticky;