import React from 'react';
import Icon from '../../Icon/Icon';

function RootSection(props) {
  const {
    itemId,
    title,
    link,
    image,
    imageAdditional,
    newSection,
    symbol,
    setHoveredRootSectionSymbol,
    depthLevel,
    hideLink,
    first,
    virtualTitle,
    hoveredRootSectionId,
    setHoverOnRootSectionId,
    height,
    hasSubsection,
  } = {...props};

  const hovered = Number(itemId) === Number(hoveredRootSectionId);

  return (
    <li className={`flex items-center justify-between cursor-pointer ${hovered
      ? 'bg-grey-7'
      : ''}`}
        style={{height: height}}
        key={`root-section-${itemId}`}>
      <a href={link}
         onMouseEnter={event => {
           if (setHoverOnRootSectionId) setHoverOnRootSectionId(Number(itemId));
           if (setHoveredRootSectionSymbol) setHoveredRootSectionSymbol(symbol);
         }}
         className={`flex gap-2 items-center w-full`}>
        <img src={hovered ? imageAdditional : image} width="24px" height="24px"
             loading="lazy" alt={title}/>
        <span className={`text-[14] leading-[21px] ${hovered
          ? 'text-blue-primary'
          : 'text-grey-90'}`}>
          {title}
        </span>
        {
          newSection && (
            <span
              className="self-start text-[10px] leading-[15px] font-medium italic text-red-mistake">new</span>
          )
        }
        {
          (hasSubsection || symbol === 'aktsii') && (
            <span className="justify-items-end grow flex justify-end">
              <Icon name="menu-right-arrow-icon"
                className={`w-[8px] h-[13px] ${hovered
                  ? 'fill-blue-primary'
                  : 'fill-grey-40'}`}/>
            </span>
          )
        }
      </a>
    </li>
  );
}

export default RootSection;