import React, {useContext, useEffect, useRef, useState} from 'react';
import {
  ClientWidthContext,
  ModalContext, ModalDispatchContext,
} from '../Context/ApplicationContextProvider';
import Icon from '../Icon/Icon';
import RootSection from './RootSection/RootSection';
import SubMenu from './SubMenu/SubMenu';

function Navigation(props) {
  const {
    rootSections,
    sectionTreeData,
    sectionTreeHeight,
    hoveredRootSectionId,
    setHeight,
    setHoverOnRootSectionId,
    setHasSubsection,
  } = {...props};

  // Состояние для hoveredRootSectionSymbol
  const [hoveredRootSectionSymbol, setHoveredRootSectionSymbol] = useState(null);

  const ref = useRef();
  const clientWidth = useContext(ClientWidthContext);
  const modals = useContext(ModalContext);
  const dispatchModal = useContext(ModalDispatchContext);

  useEffect(() => {
    if (!(ref.current instanceof HTMLElement)) {
      return;
    }
    let params = ref.current.getBoundingClientRect();
    setHeight(params.height);
  }, [ref, clientWidth]);

  return (
    <>
      <nav className={`flex gap-12 w-full`}>
        <ul className={`w-[295px] flex flex-col grow-0 shrink-0 h-max`} ref={ref}>
          {
            rootSections && Array.isArray(rootSections) &&
            rootSections.map((item, index) => (
              <RootSection key={index} {...item} height={'36px'}
                hoveredRootSectionId={hoveredRootSectionId}
                           setHoveredRootSectionSymbol={setHoveredRootSectionSymbol}
                           setHoverOnRootSectionId={setHoverOnRootSectionId}/>
            ))
          }
        </ul>
        {
          hoveredRootSectionId !== null && (
            <SubMenu {...props}
                     checkedSectionId={hoveredRootSectionId}
                     checkedSectionSymbol={hoveredRootSectionSymbol}
                     setHasSubsection={setHasSubsection}
                     sectionTreeHeight={sectionTreeHeight}/>
          )
        }
      </nav>
    </>
  );
}

export default Navigation;