import './MobileMenu.css';
import React, {useContext, useEffect, useState} from 'react';
import MobileCity from '../mobileCity/MobileCity';
import BackBtn from './images/arrow_left.svg';
import {lang} from '../../../common/baseData';
import Lang from '../../Lang/Lang';
import SearchForm from '../../SearchForm/SearchForm';
import Icon from '../../Icon/Icon';
import {
  ClientWidthContext,
  ModalContext,
} from '../../Context/ApplicationContextProvider';
import SearchResult from '../../SearchForm/SearchResult';

function MobileMenu(props) {
  const {
    opened,
    onBackBtnClick,
    activeMenuType,
    section,
    onClose,
    title,
    siteSwitch,
    onShowCitySwitch,
    citySwitch,
    children,
  } = {...props};

  const clientWidth = useContext(ClientWidthContext);
  const modals = useContext(ModalContext);

  const menuTitleClassName = 'text-[20px] leading-[30px] text-grey-90 font-semibold';
  const borderClassName = `border-solid border-b-blue-primary border-b border-x-0 border-t-0`;

  return (
    <>
      <div
        className={`fixed top-0 bottom-0 w-full z-[1071] h-[calc(100%-66px)] flex flex-col justify-between transition-all bg-white`}
        style={{
          left: opened ? 0 : clientWidth,
          maxWidth: clientWidth,
        }}>
        <div className={`flex flex-col px-5 pt-2 pb-3 ${activeMenuType ===
        'catalog' ? borderClassName : ''}`}>
          <div
            className={'flex h-10 relative items-center justify-between mobile-menu-header'}>
            {
              <span className={menuTitleClassName}>
                  {activeMenuType === 'menu'
                    ? Lang.getMessage('menu')
                    : title || 'empty'}
                </span>
            }
            <button type={'button'}
                    onClick={onClose}
                    className={'flex items-center justify-center w-10 h-10 p-0 bg-transparent border-0'}>
              <Icon name={'close-icon'}
                    className="h-4 w-4"
                    alt={'CloseIcon'}
                    title={'Close Menu'}/>
            </button>
          </div>
          {
            activeMenuType === 'catalog' && (
              <SearchForm type={'search'}>
                {
                  modals.includes('search') && (
                    <SearchResult topPosition={106} additionalClass={'pt-4'} inPageMobile={false}/>
                  )
                }
              </SearchForm>
            )
          }
        </div>
        <div className={'mobile-menu-content'}>
          {children}
        </div>
        <div className={'mobile-menu-footer'}>
          <ul className={'mobile-site-switch'}>
            {
              Array.isArray(siteSwitch) && siteSwitch.length ?
                siteSwitch.map(item => {
                  return <li key={item.LANGUAGE_ID}
                             className={`${item.SELECTED ? 'active' : ''}`}>
                    {
                      item.SELECTED ? <span>{item.TITLE}</span> :
                        <a href={item.LINK}>{item.TITLE}</a>
                    }
                  </li>;
                }) :
                <></>
            }
          </ul>
          <MobileCity
            messages={citySwitch.hasOwnProperty('messages')
              ? citySwitch.messages
              : {}}
            actualCity={citySwitch.hasOwnProperty('actualCity')
              ? citySwitch.actualCity
              : {}}
            onClick={onShowCitySwitch}/>
        </div>
      </div>
    </>
  );
}

export default MobileMenu;