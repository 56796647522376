import React, {useContext} from 'react';
import Lang from '../Lang/Lang';
import {
  eventEmitter,
  hoverUnderlineClass,
  lang,
  underlineClass,
} from '../../common/baseData';
import {
  BasketContext,
  ClientWidthContext,
} from '../Context/ApplicationContextProvider';
import Icon from '../Icon/Icon';

function ProductInSearch(props) {
  const {
    ARTICLE,
    DETAIL_PAGE_URL,
    ID,
    NAME,
    OLD_PRICE,
    PICTURE,
    PRICE,
    PRICE_VALUE,
    rootClassName,
    itemKey,
  } = props;

  const clientWidth = useContext(ClientWidthContext);
  const isDesktop = clientWidth >= 1024;

  const defaultRootClassName = `flex justify-start shrink-0 w-auto gap-5`;

  return (
    <li className={rootClassName ? rootClassName : defaultRootClassName}
        key={itemKey}>
      {/*<!-- картинка -->*/}
      <div className="flex items-center justify-center w-20 h-20 desktop:w-25">
        <img src={PICTURE}
             title={NAME}
             alt={NAME}
             className="object-fill w-full h-auto"/>
      </div>
      <div className="flex justify-between mt-1 w-full">
        {/*<!-- наименование -->*/}
        <a href={DETAIL_PAGE_URL}
           className={`text-[13px] h-max leading-[17px] text-black line-clamp-3 desktop:line-clamp-2 grow-0 ${isDesktop ? hoverUnderlineClass : ''}`}>
          {NAME}
        </a>
        <div className="flex flex-col shrink-0 ml-10 w-20">
          {/*<!-- стоимость -->*/}
          {
            OLD_PRICE && OLD_PRICE.length > 0 && (
              <span className="text-[11px] leading-[13px] line-through text-grey-50 decoration-red-line-through">
                {OLD_PRICE}
            </span>
            )
          }
          <span className="text-[14px] leading-[18px] font-medium text-grey-90">
              {PRICE}
          </span>
        </div>
      </div>
    </li>
  );
}

export default ProductInSearch;