import React, {useContext, useEffect, useState} from 'react';
import {ClientWidthContext} from '../../Context/ApplicationContextProvider';
import SubSections from './SubSections';
import Lang from '../../Lang/Lang';
import PopularSection from './PopularSection';
import SpecialDealList from './SpecialDealList'

function SubMenu(props) {
  const clientWidth = useContext(ClientWidthContext);
  const {
    rootSections,
    sections,
    popularSections,
    popularLinks,
    sectionTreeHeight,
    checkedSectionId,
    checkedSectionSymbol,
    setHasSubsection,
  } = {...props};

  const [popularBlock, setPopularBlock] = useState(null);
  const [subSections, setSubSections] = React.useState([]);

  useEffect(() => {
    if (Number.isInteger(checkedSectionId) && Number(checkedSectionId) > 0) { // формируем под меню
      let tmpSubSections = [];

      for (let subSection of sections) {
        if (!subSection.hasOwnProperty('parentId') ||
          subSection.parentId === null ||
          Number(subSection.parentId) !==
          Number(checkedSectionId)) continue;
        tmpSubSections.push(subSection);
      }

      setSubSections(tmpSubSections);
    } else { // сбрасываем подменю
      setSubSections([]);
    }
  }, [checkedSectionId]);

  /**
   * Передаем true/false выше в зависимости от наличия элементов в subSections
   */
  useEffect(() => {
    if (setHasSubsection) {
      // Условие: передаем true или false
      const hasSubsection = subSections.length > 0 || String(checkedSectionSymbol) === 'aktsii';
      setHasSubsection(hasSubsection);
    }
  }, [subSections, checkedSectionSymbol, setHasSubsection]);

  /**
   * Подготовка списка популярных категорий
   */
  useEffect(() => {
    if (!Array.isArray(popularSections) ||
      !popularSections.length) setPopularBlock(null);
    else {
      setPopularBlock(
        popularSections.find(
          section => section.hasOwnProperty('parent_id') &&
            Number(section.parent_id) === Number(checkedSectionId),
        ) || null,
      );
    }
  }, [checkedSectionId]);

  // Условие рендера: subSections не пуст или checkedSectionSymbol === 'aktsii'
  if (subSections.length === 0 && String(checkedSectionSymbol) !== 'aktsii') {
    return null;
  }

  return (
    <div
      className={`flex grow gap-5 z-20 bg-white top-0 left-0 h-full w-full`}>
      {
        popularBlock && clientWidth > 1200 && (
          <PopularSection popularBlock={popularBlock}
                          popularLinks={popularLinks}
                          sections={sections}/>
        )
      }
      <div className="flex grow gap-4 w-full overflow-hidden">
        {
          checkedSectionSymbol && String(checkedSectionSymbol) === 'aktsii'  ?
            <SpecialDealList {...props} /> :
            <SubSections subSections={subSections}
                         allSections={sections}
                         sectionTreeHeight={sectionTreeHeight}
                         popularBlockUsed={popularBlock && clientWidth > 1200}/>
        }
      </div>
    </div>
  );
}

export default SubMenu;