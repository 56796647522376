import React, {useContext, useEffect, useState} from 'react';
import Icon from '../Icon/Icon';
import Lang from '../Lang/Lang';
import {
  apiBaseUrl,
  axiosInstance,
  eventEmitter,
  langSiteDir,
} from '../../common/baseData';
import {UserContext, BasketContext} from '../Context/ApplicationContextProvider';

function UserDataDesktop(props) {
  const [showTooltip, setShowTooltip] = useState(false);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const {
    name,
    cashback,
    wishlistItemIds,
    compareItemIds,
    basketCount,
    isAuthorized,
    company,
    menuLinks
  } = {...useContext(UserContext)};

  const basketItems = useContext(BasketContext);

  function onLogout() {
    axiosInstance.post(`${apiBaseUrl}user/logout`)
    .then(response => {
      window.location.reload();
    })
    .catch(reason => {
      console.error(reason);
    })
  }

  return (
    <>
      <span className={`flex items-center border-0 bg-transparent p-0 mr-4 relative`}
              onMouseEnter={() => setShowUserMenu(true)}
              onMouseLeave={() => setShowUserMenu(false)}
              onClick={() => {
                if (isAuthorized) return;
                eventEmitter.emit('showAuth');
              }}>
        {
          isAuthorized && company && company.hasOwnProperty('icon') &&
          company.icon ?
            <img src={company.icon} className="h-6 w-6"/> :
            <Icon name={'user-icon'} className="h-6 w-6"/>
        }
        {
          isAuthorized && name && (
            <span className="ml-2 text-[14px] leading-[21px] text-grey-90">
              {name}
            </span>
          )
        }
        {
          isAuthorized && (
            <ul className={`${showUserMenu ? '' : 'hidden'} absolute flex flex-col gap-2.5 top-6 right-[-110px] items-start bg-white shadow-user-menu w-[220px] py-5 pl-10`}>
              {
                Array.isArray(menuLinks) && menuLinks.length > 0 && (
                  menuLinks.map((item, index) => (
                    <li key={index}
                        className={``}>
                      <a href={item.link}
                         className={`text-[15px] leading-[30px] text-grey-90 cursor-pointer border-solid border-b border-transparent border-0 hover:border-b-blue-primary inline-block`}>
                        {Lang.getMessage(item.code)}
                      </a>
                    </li>
                  ))
                )
              }
              <li key={'logout'}
                  className={``}>
                <span className={`text-[15px] leading-[30px] text-grey-90 cursor-pointer border-solid border-b border-transparent border-0 hover:border-b-blue-primary inline-block`}
                      onClick={(event) => {
                        console.dir(event.target);
                        event.stopPropagation();
                        onLogout();
                      }}>
                  {Lang.getMessage('logout')}
                </span>
              </li>
            </ul>
          )
        }
      </span>
      {
        isAuthorized && (
          <span
            className="relative flex flex-nowrap gap-[5px] w-auto h-5 bg-grey-100 rounded-10 px-1.5 items-center mr-1 cursor-help"
            onMouseEnter={event => (setShowTooltip(true))}
            onMouseLeave={event => (setShowTooltip(false))}>
          <Icon name={'cashback-icon'} className="h-[11px] w-[15px]"/>
            <span className="text-[11px]">
              {cashback}
            </span>
            {
              showTooltip ?
                <div
                  className="absolute top-6 right-0 bg-white rounded-md shadow-lg p-3 w-52 text-[12px] leading-[16px] text-grey-90 text-center">
                  {Lang.getMessage('cashbackTooltip')}
                </div> :
                <></>
            }
          </span>
        )
      }
      {
        Array.isArray(wishlistItemIds) && wishlistItemIds.length > 0 && (
          <span className="relative flex justify-center items-center w-10 h-10">
            <Icon name={'wishlist-icon'}
                  className={`h-6 w-6 hover:fill-blue-primary cursor-pointer`}
                  onClick={() => {
                    window.location.href = `${langSiteDir}personal/wishlist/`;
                  }}/>
            <span
                  className="absolute right-0 top-0 bg-red-info w-4 h-4 flex items-center justify-center text-[11px] text-white rounded-10">
                {wishlistItemIds.length}
            </span>
          </span>
        )
      }
      {
        Array.isArray(compareItemIds) && compareItemIds.length > 0 && (
          <span className="relative flex justify-center items-center w-10 h-10">
            <Icon name={'compare-icon'}
                  className={`h-[27px] w-[35px] hover:fill-blue-primary cursor-pointer`}
                  onClick={() => {
                    window.location.href = `${langSiteDir}personal/compare/`;
                  }}/>
            <span
              className="absolute right-0 top-0 bg-red-info w-4 h-4 flex items-center justify-center text-[11px] text-white rounded-10">
              {compareItemIds.length}
            </span>
          </span>
        )
      }
      <div className="flex items-center cursor-pointer"
           onClick={event => {
             if (!Array.isArray(basketItems)) return;
             eventEmitter.emit('openSmallCart');
           }}>
        <span className="relative flex items-center justify-center h-10 w-10">
          <Icon name={'basket-icon'} className="h-[23px] w-[22px]"/>
          {
            Array.isArray(basketItems) && basketItems.length > 0 ?
              <span
                className="absolute right-0 top-0 bg-red-info w-4 h-4 flex items-center justify-center text-[11px] text-white rounded-10">
                    {basketItems.length}
                </span> :
              <></>
          }
        </span>
        <span className="text-[14px] leading-[21px] text-grey-90">
          {Lang.getMessage('CART')}
        </span>
      </div>
    </>
  );
}

export default UserDataDesktop;