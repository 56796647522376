import React, {useContext} from 'react';
import Lang from '../Lang/Lang';
import {lang} from '../../common/baseData';
import Icon from '../Icon/Icon';
import {ClientWidthContext} from '../Context/ApplicationContextProvider';

function FooterMenu(props) {
  const {
    type,
    title,
    items,
  } = props;
  const [opened, setOpened] = React.useState(false);

  const clientWidth = useContext(ClientWidthContext);
  const isMobile = clientWidth < 1280;

  const listWrapClass = `flex justify-between desktop:flex-col desktop:gap-3 desktop:justify-start desktop:items-start`;
  const listTitleClass = `text-[14px] leading-[17px] desktop:text-[16px] desktop:leading-[24px] font-medium text-black`;
  const itemTextClass = `text-[14px] leading-[17px] desktop:text-[16px] desktop:leading-[24px] text-grey-90`;

  return (
    <div className={listWrapClass}
         onClick={(event) => {
           if (!isMobile) return;
           event.stopPropagation();
           setOpened(!opened);
         }}>
      <div className={`flex justify-between flex-col gap-3`}>
        <span className={listTitleClass}>{title}</span>
        <ul className={`${isMobile && !opened
          ? 'hidden'
          : 'flex'} flex-col gap-3`}>
          {
            items.map((item) => (
              <li key={`${type}-${item.link}`}>
                <a href={item.link}
                   className={itemTextClass}>
                  {item.text}
                </a>
              </li>
            ))
          }
        </ul>
      </div>
      {
        isMobile && (
          <Icon name={'dropdown-arrow'}
                className="w-[13px] h-[8px] fill-black"/>
        )
      }
    </div>
  );
}

export default FooterMenu;