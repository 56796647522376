import React from 'react';
import icons from './sprite-icons.svg';

const Icon = props => {
  function onClick(event) {
    if (props.onClick) props.onClick(event);
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={`${props.className}`}
      style={props.hasOwnProperty('style') ? props.style : {}}
      onClick={onClick}
    >
      <use xlinkHref={`${icons}#${props.name}`}/>
    </svg>
  );
};

export default Icon;