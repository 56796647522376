import React, {useEffect, useState} from 'react';
import Icon from '../../Icon/Icon';

function MobileCatalog(props) {
  const {
    active,
    activeSectionId,
    findParentSection,
    section,
    onBackBtnClick,
    setActiveCatalogSection,
    items,
    popBlock,
  } = {...props};

  const [sections, setSections] = useState([]);
  const parent =

  // make sections array
  useEffect(() => {
    if (activeSectionId > 0) {
      setSections(items.filter(item => {
        return item.hasOwnProperty('parentId') && Number(item.parentId) > 0 &&
          Number(item.parentId) === activeSectionId;
      }).sort((a, b) => {
        if (isNaN(a.sortValue) || isNaN(b.sortValue) || Number(a.sortValue) ===
          Number(b.sortValue)) return 0;
        return Number(a.sortValue) > Number(b.sortValue) ? 1 : -1;
      }));
    } else {
      setSections(items.filter(item => {
        return item.hasOwnProperty('depthLevel') && Number(item.depthLevel) ===
          1;
      }).sort((a, b) => {
        if (isNaN(a.sortValue) || isNaN(b.sortValue) || Number(a.sortValue) ===
          Number(b.sortValue)) return 0;
        return Number(a.sortValue) > Number(b.sortValue) ? 1 : -1;
      }));
    }
  }, [activeSectionId]);

  function hasChildren(id) {
    let hasChildren = false;

    items.map(item => {
      if (item.hasOwnProperty('parentId') && Number(item.parentId) > 0 &&
        Number(item.parentId) === Number(id))
        hasChildren = true;
    });

    return hasChildren;
  }

  function getPopularImageLink() {
    return popBlock && typeof popBlock.mobile_pict_subdir === 'string' &&
    typeof popBlock.mobile_pict_filename === 'string' &&
    popBlock.mobile_pict_subdir.length &&
    popBlock.mobile_pict_filename.length
      ? `/upload/${popBlock.mobile_pict_subdir}/${popBlock.mobile_pict_filename}`
      : null;
  }

  function onClick(event, item) {
    if (item.hasOwnProperty('depthLevel') && Number(item.depthLevel) === 3 &&
      item.hasOwnProperty('link'))
      window.location.href = item.link;
    else if (item.hasOwnProperty('itemId')) {
      // check children
      if (!hasChildren(item.itemId)) {
        window.location.href = item.link;
      } else {
        // proxy active catalog section to parent
        setActiveCatalogSection(item);
      }
    }
  }

  return (
    <div className={`mx-5 mt-4 ${section === null ? 'mb-8' : 'mb-5' }`}>
      {
        section !== null && (
          <div className={'flex gap-2 items-center mb-2'}>
            <span className={'flex items-center justify-center w-6 h-6'}
                  onClick={() => (onBackBtnClick())}>
              <Icon name={'menu-left-arrow-icon'} className={'w-[8px] h-[13px] fill-blue-primary'} />
            </span>
            <a href={section.link}
               className={`text-[20px] leading-[30px] text-blue-primary`}>
              {section.title}
            </a>
          </div>
        )
      }
      {
        getPopularImageLink() !== null && !Number(popBlock.hide_popular_on_mob)
          ?
          <a href={popBlock.banner_link ? `${popBlock.banner_link}` : ''}
             className="mobile-menu-banne-link">
            <img src={getPopularImageLink()}/>
          </a>
          :
          <></>
      }
        <ul className={`flex flex-col gap-2.5`}>
          {
            popBlock && popBlock.hasOwnProperty('links') &&
            !Number(popBlock.hide_popular_on_mob) ?
              popBlock.links.map(item => {
                return <li key={item.rec_id}>
                  <a href={item.path}>
                    {item.title}
                    {
                      item.hasOwnProperty('is_new') && !!Number(item.is_new) ?
                        <span className={'new'} style={{
                          color: popBlock.hasOwnProperty('label_new_color') &&
                          popBlock.label_new_color.length ?
                            `#${popBlock.label_new_color}` : '',
                        }}>NEW</span> :
                        <></>
                    }
                  </a>
                </li>;
              }) :
              <></>
          }
          {
            sections.map((item => (
              <li className={`flex items-center ${item.depthLevel < 3 ? 'justify-between' : ''} h-[24px] gap-2 w-full`}
                key={item.itemId}
                onClick={event => onClick(event, item)}>
                {
                  Number(item.depthLevel) === 1 &&
                  item.hasOwnProperty('image') && item.image &&
                  item.image.length &&
                  item.hasOwnProperty('imageAdditional') &&
                  item.imageAdditional &&
                  item.imageAdditional.length && (
                    <img src={active ? item.imageAdditional : item.image}
                         alt={item.title} title={item.title} width="24px"
                         height="24px"/>
                  )
                }
                <span className="text-[15px] leading-[21px] text-grey-90">
                    {item.title}
                  </span>
                {
                  Number(item.newSection) > 0 && (
                    <span className="self-start text-[10px] leading-[15px] font-medium italic text-red-mistake">new</span>
                  )
                }
                {
                  item.depthLevel < 3 && (
                    <span className={'justify-items-end grow flex justify-end'}>
                      <Icon name={`menu-right-arrow-icon`}
                            className={'w-[8px] h-[13px]'}/>
                    </span>
                  )
                }
              </li>
            )))
          }
        </ul>
    </div>
  );
}

export default MobileCatalog;