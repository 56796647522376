import './MobileCitySwitch.css';
import React, {useEffect, useRef, useState} from 'react';
import ClearIcon from './images/close.svg';
import {axiosInstance} from '../../../common/baseData';

function MobileCitySwitch(props) {
  const [searchText, setSearchText] = useState('');
  const [filteredCities, setFilteredCities] = useState([]);

  const inputRef = useRef();

  const config = {headers: {'X-Requested-With': 'XMLHttpRequest'}};

  // filter city list when user input search text
  useEffect(() => {
    if (!searchText.length) setFilteredCities(props.cities);
    else {
      let cities = [];

      props.cities.map(city => {
        // check search text input
        if (city.hasOwnProperty('NAME') && city.NAME.length &&
            city.NAME.toLowerCase().indexOf(searchText.toLowerCase()) !==
            -1) cities.push(city);
      });

      setFilteredCities(cities);
    }
  }, [searchText]);

  function switchCity(cityId) {
    return new Promise((resolve, reject) => {
      let data = {
        action: 'cityChangeAction',
        id: cityId,
        path: window.location.pathname,
      };
      axiosInstance.post(props.ajaxPath, data, {
        headers: {'Content-Type': 'multipart/form-data'},
      }).
          then(response => (resolve(response.data))).
          catch(reason => (resolve(null)));
    });
  }

  // Event: user input search text
  function onSearch(event) {
    setSearchText(event.target.value.trim());
  }

  // Event: user check city in list
  async function onCheckCity(cityData) {
    //console.info('onCheckCity', cityData)
    let response = await switchCity(Number(cityData.ID));
    //console.info(response);
    if (response && response.status) {
      // Очистка города доставки выбранного на странице товара
      window.localStorage.removeItem('delivery_city');
      window.location.href = response.link && typeof response.link === 'string'
          ? response.link
          : cityData.LINK;
    }
  }

  // Event: user request clear search text
  function onClearSearch(event) {
    setSearchText('');
    focusOnInput();
  }

  function focusOnInput() {
    if (inputRef.current !== null && inputRef.current instanceof HTMLElement) {
      inputRef.current.focus();
    }
  }

  const placeholder = props.hasOwnProperty('messages') &&
  props.messages.hasOwnProperty('CITY_SWITCH_SEARCH') ?
      props.messages['CITY_SWITCH_SEARCH'] : 'City search';

  const filteredList = filteredCities.map(city => {
    let active = city.hasOwnProperty('ACTIVE') && city.ACTIVE === true;
    return <li key={city.ID} className={`${active ? 'active' : ''}`}
               onClick={event => onCheckCity(city)}>
      <span>{city.NAME}</span>
    </li>;
  });

  return (
      <div className={'mobile-city-switch'}>
        <div className={'mobile-city-switch-search'}>
          <form onSubmit={event => event.preventDefault()}>
            <input ref={inputRef}
                   type={'text'}
                   autoComplete={'off'}
                   onChange={onSearch}
                   placeholder={placeholder}
                   value={searchText}/>
            <img src={ClearIcon}
                 alt={'Clear Icon'}
                 title={'Clear Icon'}
                 width={'16px'}
                 height={'16px'} onClick={onClearSearch}/>
          </form>
        </div>
        <ul className={'mobile-city-switch-list'}>{filteredList}</ul>
      </div>
  );
}

export default MobileCitySwitch;