import React from 'react';
import Lang from './../Lang/Lang';
import Icon from '../Icon/Icon';
import {hoverUnderlineClass, underlineClass} from '../../common/baseData';

function CityListDropdown(props) {
  const {onClose, onSwitch, items, currentCityId} = {...props}

  const isNoFollow = window.location.pathname.includes('ch-');
  const activeClassName = `text-grey-90 border-b-2 border-b-blue-primary cursor-default ${underlineClass}`;
  const defaultClassName = `text-blue-primary cursor-pointer ${hoverUnderlineClass}`;

  return (
    <div className="city-switch-dropdown bg-white shadow-city-select-shadow absolute top-[29px] right-0 transition-all duration-300 w-[500px] z-[110] p-7">
      <Icon name={'close-icon'}
            className={'h-4 w-4 inline-block absolute top-4 right-4 cursor-pointer'}
            onClick={onClose}/>
      <span className="inline-block text-[20px] tracking-[0.1px] text-grey-90 font-normal leading-[24px] my-6 w-full text-left">
        {Lang.getMessage('choiceCity')}
      </span>
      <div className="grid grid-cols-3">
        {
          items && Array.isArray(items) ?
            items.map(city => (
              <a href={city.link}
                 key={`city-${city.id}`}
                 rel={isNoFollow ? 'nofollow' : undefined}
                 onClick={event => {
                   event.preventDefault();
                   onSwitch(city, true);
                 }}
                 className={`inline-block pb-[5px] max-w-[110px] w-max text-left mb-[10px] text-[16px] mt-[10px] h-max ${(Number(
                   city.id) === currentCityId)
                   ? activeClassName
                   : defaultClassName}`}>
                {city.title}
              </a>
            )) :
            <></>
        }
      </div>
    </div>
  );
}

export default CityListDropdown;