import React, {useState, useEffect, useRef, useContext} from 'react';
import Navigation from '../Navigation/Navigation';
import {
  ClientWidthContext,
  ModalContext,
  ModalDispatchContext,
} from '../Context/ApplicationContextProvider';
import {eventEmitter} from '../../common/baseData';

function DropdownNavigation(props) {
  const modals = useContext(ModalContext);
  const dispatchModal = useContext(ModalDispatchContext);
  const clientWidth = useContext(ClientWidthContext);

  const {sticky, page} = {...props};
  const [hoveredRootSectionId, setHoverOnRootSectionId] = React.useState(null);
  const [sectionTreeHeight, setSectionTreeHeight] = React.useState(null);

  const [sectionTreeData, setSectionTreeData] = useState(window.sectionTree || {
    rootSections: null,
    sections: null,
    popularSections: null,
    popularLinks: null,
  });

  const [hasSubsection, setHasSubsection] = useState(false);
  const sectionClass = sticky  ? 'top-[1px] left-0' : 'top-0 left-[-20px] overflow-hidden';

  const [showNavigation, setShowNavigation] = React.useState(
    modals.includes('navigation'));

  // Условие для sectionCheckedClass
  const sectionCheckedClass = (() => {
    if (sticky && Number(hoveredRootSectionId) && hasSubsection) {
      return 'w-full'; // sticky, раздел выбран, есть подкатегории
    }
    if (Number(hoveredRootSectionId) && hasSubsection && !sticky) {
      return 'w-[calc(100%+40px)]'; // Не sticky, раздел выбран, есть подкатегории
    }
    if (Number(hoveredRootSectionId) && !hasSubsection && !sticky) {
      return 'w-fit'; // Не sticky, раздел выбран, нет подкатегорий
    }
    return 'w-fit'; // По умолчанию
  })();

  useEffect(() => {
    setShowNavigation(modals.includes('navigation'));
  }, [modals]);

  useEffect(() => {
    eventEmitter.on('closeNavigation', dropCheckedSection);

    return () => {
      eventEmitter.off('closeNavigation', dropCheckedSection);
    }
  }, []);

  function dropCheckedSection() {
    setHoverOnRootSectionId(null);
  }

  return (
    <div className={`${showNavigation ? 'opacity-1 z-10 pointer-events-auto' : 'opacity-0 z-0 pointer-events-none'} transition-all duration-500 relative self-end w-full max-w-[1600px] m-auto`}
         onMouseLeave={() => {
           dispatchModal({type: 'hide', modalType: 'navigation'});
         }}>
      <section id="navigation-root"
               className={`absolute ${sectionCheckedClass} p-5 pt-6 ${sectionClass} right-0 flex gap-12 z-30 bg-white`}>
        <Navigation setHeight={setSectionTreeHeight}
                     {...sectionTreeData}
                     hoveredRootSectionId={hoveredRootSectionId}
                     sectionTreeHeight={sectionTreeHeight}
                     setHasSubsection={setHasSubsection}
                     setHoverOnRootSectionId={setHoverOnRootSectionId}/>
      </section>
    </div>
  );
}

export default DropdownNavigation;