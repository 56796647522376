import React, {useEffect} from 'react';
import Lang from '../../Lang/Lang';

function PopularSection(props) {
  const {popularBlock, popularLinks, sections} = {...props};

  const bgColor = popularBlock.hasOwnProperty(
    'background_color') && popularBlock.background_color.length
    ? `#${popularBlock.background_color}`
    : 'linear-gradient(0deg, rgba(0, 128, 204, 0.05), rgba(0, 128, 204, 0.05)), #FFFFFF';

  const fontColor = popularBlock &&
  popularBlock.hasOwnProperty('font_color') &&
  popularBlock.font_color.length
    ? `#${popularBlock.font_color}`
    : 'text-grey-90';

  const labelColor = popularBlock.hasOwnProperty('label_new_color') &&
  popularBlock.label_new_color.length
    ? `#${popularBlock.label_new_color}`
    : '';

  const [filteredLinks, setFilteredLinks] = React.useState([]);
  const imageLink = popularBlock &&
  typeof popularBlock.desktop_pict_subdir === 'string' &&
  typeof popularBlock.desktop_pict_filename === 'string' &&
  popularBlock.desktop_pict_subdir.length &&
  popularBlock.desktop_pict_filename.length
    ? `/upload/${popularBlock.desktop_pict_subdir}/${popularBlock.desktop_pict_filename}`
    : null;

  useEffect(() => {
    let filtered = popularLinks.filter(
      pop_link => popularBlock.link_id.includes(pop_link.rec_id),
    );

    let sorted = filtered.sort((a, b) => {
      if (isNaN(a.sort) || isNaN(b.sort) || Number(a.sort) ===
        Number(b.sort)) return 0;
      return Number(a.sort) > Number(b.sort) ? 1 : -1;
    });

    setFilteredLinks(sorted);
  }, [popularLinks]);

  function getPopularSections() {
    let pop_section_ids = popularBlock.hasOwnProperty('child_id') &&
    Array.isArray(popularBlock.child_id) ? popularBlock.child_id : [];

    let items = sections.filter(
      section => pop_section_ids.includes(section.itemId));
    let sorted = items.sort((a, b) => {
      if (isNaN(a.sortValue) || isNaN(b.sortValue) || Number(a.sortValue) ===
        Number(b.sortValue)) return 0;
      return Number(a.sortValue) > Number(b.sortValue) ? 1 : -1;
    });

    return sorted;
  }

  function getLink(linkId) {
    return popularLinks.find(pop_link => linkId == pop_link.rec_id);
  }

  return (
    <div
      className="flex flex-col rounded-[1px] overflow-hidden basis-1/5 h-full min-w-[210px] max-w-[230px] p-4 gap-2.5"
      style={{background: bgColor}}>
      {
        popularBlock && popularBlock.hasOwnProperty('title_link_id') &&
        Number(popularBlock.title_link_id) ?
          (
            !Number(getLink(popularBlock.title_link_id).hide_title) ?
              <a href={getLink(popularBlock.title_link_id) !== undefined
                ? getLink(popularBlock.title_link_id).path
                : '#'}
                 className="text-[16px] leading-[20px] text-blue-primary"
                 style={{color: fontColor}}>
                {
                  getLink(popularBlock.title_link_id) !== undefined ?
                    getLink(popularBlock.title_link_id).title : ''}
              </a> :
              <></>
          ) :
          <div className="text-[16px] leading-[20px] text-grey-90"
               style={{color: fontColor}}>
            {
              Lang.getMessage('popularTitle')
            }
          </div>
      }

      {
        imageLink !== null ?
          <a
            href={popularBlock.banner_link ? `${popularBlock.banner_link}` : ''}
            className="h-[260px] hover:border-0">
            <img src={imageLink} className={'w-full h-full object-cover'}/>
          </a> :
          <></>
      }

      {
        popularBlock && popularBlock.hasOwnProperty('link_id') &&
        Array.isArray(popularBlock.link_id) && popularBlock.link_id.length ?
          <ul className={'p-0 m-0 flex flex-col gap-2.5'}>
            {
              filteredLinks.map(link => (
                <li key={link.rec_id} className={'flex items-start gap-1.5'}>
                  <a href={link.path}
                     className={'text-grey-80 text-[13px] leading-[16px] hover:border-transparent hover:underline'}
                     style={{color: fontColor}}>
                    {link.title}
                  </a>
                  {
                    link.hasOwnProperty('is_new') && !!Number(link.is_new) ?
                      <span className={'text-[10px] leading-[10px] h-[10px]'}
                            style={{color: labelColor}}>
                        new
                      </span> :
                      <></>
                  }
                </li>
              ))
            }
          </ul> :
          <></>
      }

      {
        getPopularSections().map(popular_section => (
          <a href={popular_section.link}
             className="side-menu-link"
             style={{color: fontColor}}
             key={`popular-${popular_section.itemId}`}>
            {popular_section.title}
          </a>
        ))
      }

    </div>
  );
}

export default PopularSection;