import React, {useContext, useEffect, useRef, useState} from 'react';
import {
  ClientWidthContext, ModalContext, ModalDispatchContext,
} from '../Context/ApplicationContextProvider';
import DropdownNavigation from '../DropdownNavigation/DropdownNavigation';
import {eventEmitter} from '../../common/baseData';

function SecondLine(props) {
  const {
    children,
    sticky,
    setSticky,
    setShowOnProduct,
    page,
  } = props;

  const clientWidth = useContext(ClientWidthContext);
  const modals = useContext(ModalContext);

  const isMobile = clientWidth < 1024;

  const rootStickyClass = `fixed top-0 left-0 right-0 border-0 border-solid border-b border-blue-primary ${isMobile
    ? 'py-3 px-5'
    : ''}`;
  const rootNotStickyClass = 'max-w-[1600px] py-4 px-5';

  const subStickyClass = !isMobile ? `max-w-[1600px] py-3 px-5` : ``;
  const subNotStickyClass = ``;

  const ref = useRef(null);
  const filterSectionRef = useRef(null);

  useEffect(() => {
    // Функция проверки скролла
    function checkSticky() {
      if (!(ref.current instanceof HTMLElement)) {
        return;
      }

      // Проверка на наличие класса opened у filter-section
      const filterSection = document.querySelector('.filter-section');
      const isFilterSectionOpened = filterSection && filterSection.classList.contains('opened');

      // Если класс opened присутствует, пропускаем выполнение setSticky
      if (isFilterSectionOpened) {
        return;
      }

      let coords = ref.current.getBoundingClientRect();
      if (page === 'Product') {
        setShowOnProduct(window.pageYOffset >= coords.bottom)
      } else {
        setSticky(window.pageYOffset >= (isMobile ? coords.bottom + 12 : coords.bottom))
      }
    }

    // Добавляем слушатель на скролл
    window.addEventListener('scroll', checkSticky);
    return () => {
      window.removeEventListener('scroll', checkSticky);
    };
  }, [page, isMobile, setSticky, setShowOnProduct]);

  useEffect(() => {
    // Ссылка на элемент с классом filter-section
    filterSectionRef.current = document.querySelector('.filter-section');

    if (!filterSectionRef.current) return;

    // Настраиваем MutationObserver
    const observer = new MutationObserver((mutationsList) => {
      for (let mutation of mutationsList) {
        if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
          const hasOpenedClass = filterSectionRef.current.classList.contains('opened');
          setSticky(hasOpenedClass);
        }
      }
    });

    // Наблюдаем за изменениями атрибутов класса
    observer.observe(filterSectionRef.current, { attributes: true });

    // Очищаем observer при размонтировании компонента
    return () => observer.disconnect();
  }, [setSticky]);

  return (
      <div
          className={`flex flex-col bg-white w-full items-center phone:pt-2.5 ${page === 'Section' ? 'z-50' : 'z-[100]'} ${sticky
              ? rootStickyClass
              : rootNotStickyClass}`}
      >
        <div
            className={`flex justify-between items-center w-full ${sticky
                ? subStickyClass
                : subNotStickyClass}`}
            ref={ref}
        >
          {children}
        </div>
        {/** навигация / меню каталога **/}
        <DropdownNavigation sticky={sticky} page={page} />
      </div>
  );
}

export default SecondLine;