import React, {useContext, useEffect, useRef, useState} from 'react';
import Icon from '../Icon/Icon';
import Lang from '../Lang/Lang';
import {ModalDispatchContext} from '../Context/ApplicationContextProvider';

function MobileSearchActivatorForm(props) {
  const dispatchModal = useContext(ModalDispatchContext);

  function toggleMobileSearch(event) {
    event.target.blur();
    event.preventDefault();
    event.stopPropagation();
    dispatchModal({type: 'show', modalType: 'mobileSearch'});
  }

  return (
    <form name="mobile-search-activator" className={'relative w-full flex h-[35px] grow'}>
        <span className="absolute left-3 flex justify-center items-center h-full">
          <Icon name={'search-icon'} className="h-4 w-4"/>
        </span>
      <input type="text"
             autoComplete="off"
             placeholder={Lang.getMessage('search')}
             className="w-full p-3 px-10 h-full flex gap-3 border-grey-20 border border-solid text-[14px] leading-[21px] text-grey-90 desktop:border-r-0"
             onFocus={e => (toggleMobileSearch(e))}/>
    </form>
  )
}

export default MobileSearchActivatorForm;