'use strict'

import './MobilePersonal.css'
import React from 'react'

function MobilePersonal (props) {
  return (
    <ul className={'mobile-personal'}>
      {
        props.items.map((item, i) => {
          return <li key={i} className={`${item.class} ${item.selected ? 'active' : ''}`} onClick={event => {
            if (item.hasOwnProperty('event') && item.event === 'openBasket') {
              event.preventDefault()
              props.initOpenBasket()
            }
          }}>
            <a href={item.link}>{item.title}</a>
          </li>
        })
      }
    </ul>
  )
}

export default MobilePersonal