import React from 'react';
import {hoverUnderlineClass} from '../common/baseData';

function StaticMenu(props) {
  const [items, setItems] = React.useState(window.headerStaticMenu || []);

  const classRef = {
    'yellow': 'bg-yellow-10 rounded px-3 py-2'
  }

  return (
    <ul className='hidden desktop:flex gap-8'>
      {
        items.map((item, index) => (
          <li key={index}>
            <a href={item.link}
               className={`text-[14px] leading-[21px] text-grey-90 no-underline ${hoverUnderlineClass} ${item.color && classRef[item.color]}`}>
              {item.title}
            </a>
          </li>
        ))
      }
    </ul>
  )
}

export default StaticMenu;