import React, {useEffect, useState} from 'react';
import UserDataDesktop from './Desktop';
import UserDataMobile from './Mobile';
import {apiBaseUrl, axiosInstance, userId} from '../../common/baseData';

function UserData(props) {

  return (
    <>
      {
        props.clientWidth >= 1280 ?
          <UserDataDesktop/> :
          <UserDataMobile/>
      }
    </>
  );
}

export default UserData;